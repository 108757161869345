import React, { useEffect, useState } from "react";
import { usePlans, useTieredPlans } from "hooks/Checkout";
import { BillingPeriodUnit, SubscriptionProvider } from "services/graphql";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import { setSelectedPlan } from "modules/checkout";
import { useDispatch } from "react-redux";
import { usePrimarySubscription } from "hooks/Subscriptions/usePrimarySubscription";
import { Redirect } from "react-router";
import { Routes } from "constants/routes";
import { AccountBodyWrapper } from "../../AccountBodyWrapper";
import { PlanSelect } from "./PlanSelect";

const SwitchPlan = () => {
  const dispatch = useDispatch();
  const [selectedBillingPeriod, setSelectedBillingPeriod] = useState(
    BillingPeriodUnit.Year
  );
  const { data: plansData, loading: loadingPlansData } = usePlans();
  const { plans } = plansData || {};
  const { tieredPlans } = useTieredPlans({
    plans,
    billingPeriodUnit: selectedBillingPeriod,
  });
  const {
    primarySubscription,
    loadingPrimarySubscription,
  } = usePrimarySubscription();

  const displayedPlans = tieredPlans
    ? Object.keys(tieredPlans)
        .map(key => tieredPlans[key as any])
        .sort((a, b) => a.priceInCents - b.priceInCents)
    : null;

  useEffect(() => {
    if (!displayedPlans) {
      return;
    }

    // @TODO: Best value on the back-end is returning true if plan is yearly, might need to update to return true only if standard or premium
    const bestValuePlan = displayedPlans
      .filter(plan => plan.isBestValue)
      .sort((a, b) => b.priceInCents - a.priceInCents)[0];
    dispatch(setSelectedPlan(bestValuePlan));
  }, [plans]);

  if (loadingPlansData || loadingPrimarySubscription) {
    return <LoaderCentered />;
  }

  if (!primarySubscription) {
    return <Redirect to={Routes.account__subscription} />;
  }

  if (primarySubscription.provider.name === SubscriptionProvider.Revenuecat) {
    return <Redirect to={Routes.account__subscription} />;
  }

  return (
    <PlanSelect
      headerText="Manage Your Plan"
      plans={displayedPlans}
      selectedBillingPeriod={selectedBillingPeriod}
      setSelectedBillingPeriod={setSelectedBillingPeriod}
    />
  );
};

export default () => (
  <AccountBodyWrapper>
    <SwitchPlan />
  </AccountBodyWrapper>
);
